import { graphql, useStaticQuery } from "gatsby"

const useLimitReviews = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiReview(
        sort: { fields: date, order: DESC }
        filter: { publish: { eq: true } }
      ) {
        nodes {
          ...reviewFragment
        }
      }
    }
  `)

  const reviews = data?.allStrapiReview?.nodes

  return reviews
}

export default useLimitReviews
